/* eslint-disable react/prop-types */
/* eslint-disable no-bitwise */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-children-prop */
import React, { useRef, useState } from 'react';
import { useNavigate } from '@reach/router';
import { Text, InputGroup, Input } from '@chakra-ui/react';
import { slugify } from '../utils/slugify';
import { SpecialsSearchBarStyles } from './AdviceSearchBar';

export const SearchPageSearchBar = ({ type }) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const searchRef = useRef(null);
  const typePluralCorrected =
    type === 'post' ? 'posts' : `${type.toLowerCase()}s`;

  const isEnterPressed = async (e) => {
    if (e.keyCode === 13) {
      if (!search.trim()) {
        return;
      }
      navigate(`/search/${typePluralCorrected}/?s=${slugify(search)}`);
      if (searchRef?.current) {
        searchRef.current.blur();
      }
    }
  };

  return (
    <SpecialsSearchBarStyles className="search">
      <Text className="label search-label">
        Search <span id="results"> {typePluralCorrected}</span>
      </Text>
      <InputGroup className="search-input-wrapper">
        <Input
          focusBorderColor="none"
          className="search-input"
          autoComplete="off"
          type="text"
          id="search"
          name="search"
          ref={searchRef}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => isEnterPressed(e)}
        />
      </InputGroup>
    </SpecialsSearchBarStyles>
  );
};

/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Text, Box, Button } from '@chakra-ui/react';
// import { SpecialsHeaderStyles } from './AdviceSortByHeader';
import { SearchPageSearchBar } from './SearchPageSearchBar';

const SpecialsHeaderStyles = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding-bottom: 1rem;
  @media (max-width: 640px) {
    grid-template-columns: 140px 90px 90px;
  }
  .label {
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: -3px;
    @media (max-width: 640px) {
      font-size: 12px;
    }
  }
  .browse-by-select {
    height: 30px;
    font-size: 13px;
    padding: 0 1.8rem 0 1rem;
    background: #f9f9f9;
  }
  /* #show-results-for {
    @media (max-width: 414px) {
      display: none;
    }
  } */
  .button {
    font-size: 13px;
    font-weight: 400;
    height: 30px;
    background: #f9f9f9;
    border: 1px solid #e2e8f0;
    &:hover {
      background: #cbd5e0;
    }
    @media (max-width: 840px) {
      width: 100%;
    }
  }
  #selected {
    background: #cbd5e0;
  }
  .search-wrapper {
    justify-self: end;
    @media (max-width: 640px) {
      justify-self: start;
      grid-row: 2 / span 1;
      grid-column: 1 / -1;
    }
    /* @media (max-width: 414px) {
      grid-row: 1 / span 1;
    } */
  }
  #show {
    @media (max-width: 840px) {
      display: none;
    }
    @media (max-width: 640px) {
      display: inline-block;
    }
  }
`;

export const SearchResultsHeader = ({ searchTerm, type }) => (
  <>
    <SpecialsHeaderStyles className="search">
      <Text className="label" id="show-results-for">
        <span id="show">Show </span> results for
      </Text>
      <Link to={`/search/comedians/?s=${searchTerm}`}>
        <Button className="button" id={type === 'Comedian' ? 'selected' : ''}>
          Comedians
        </Button>
      </Link>
      <Link to={`/search/posts/?s=${searchTerm}`}>
        <Button className="button" id={type === 'Post' ? 'selected' : ''}>
          Posts
        </Button>
      </Link>
      <Box className="search-wrapper">
        <SearchPageSearchBar type={type} />
      </Box>
    </SpecialsHeaderStyles>
  </>
);

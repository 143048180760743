/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import { ComedianImageSmall } from './ComedianImageSmall';
import { GenericHeader } from './GenericHeader';
import { AdviceContentSingle } from './AdviceContentSingle';
import { generateId } from '../utils/generateId';
import { ComediansGridStyles } from './ComediansGrid';
import { AbsoluteHeaderStyles, AdviceGridStyles } from './AdviceGrid';
import { SearchResultsHeader } from './SearchResultsHeader';

export const SearchResults = ({ resultsArr, type, searchTerm, totalLength, page }) => {
    const typeFormatted = type === 'Post' ? 'post' : type.toLowerCase();
    const searchTermDesluggified = searchTerm.split('-').join(' ');
    const header =
    type === 'Post' && page && page > 1
      ? `Posts - Page ${page || 1}`
      : 'Posts';
  return (
    <Box style={{ marginTop: '-4px'}}>
        <AbsoluteHeaderStyles id="search">
            <SearchResultsHeader searchTerm={searchTerm} type={type} />
        </AbsoluteHeaderStyles>
        <GenericHeader header={type === 'Post' ? header : `${type}s`}  />
        <Text fontSize="13px" fontWeight="500" color="#4056a1" margin="10px 0" textAlign="center">{totalLength} {typeFormatted}{totalLength === 1 ? '' : 's'} found for <span style={{ fontWeight: '700'}}>'{searchTermDesluggified}'</span>.</Text>
        {type === 'Comedian' && (
            <ComediansGridStyles>
            {resultsArr.map((comedian, index) => (
                <ComedianImageSmall comedian={comedian} key={generateId()} index={index} standalonePage />
            ))}
        </ComediansGridStyles>
        )}
        {type === 'Post' && (
            <AdviceGridStyles>
                {resultsArr.map((advice, index) => {
                    return (
                        <AdviceContentSingle key={generateId()} advice={advice} index={index} />
                    )
                })}
            </AdviceGridStyles>
        )}
    </Box>
  )
};
